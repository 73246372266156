import React from 'react';

function WreathIcon(props) {

  const {
    size = 24,
    color = 'currentColor',
    style,
    strokeWidth = 1.5,
  } = props;

  return (
    <svg style={style} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        d="M14 4C14 5.10457 13.1046 6 12 6C10.8954 6 10 5.10457 10 4M14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4M14 4C14 4 16.4768 2 17.8301 2C19.1835 2 20.6603 2.64664 20.6603 4C20.6603 5.2468 19.4069 5.89379 18.1513 5.98795M14 4C14 4 16.2682 5.83158 17.6495 5.98921C17.7116 5.9963 17.7719 6 17.8301 6C17.9367 6 18.044 5.99599 18.1513 5.98795M10 4C10 4 7.52323 2 6.16987 2C4.81651 2 3.33975 2.64664 3.33975 4C3.33975 5.24673 4.59297 5.89371 5.84846 5.98793M10 4C10 4 7.7318 5.83158 6.35053 5.98921C6.28842 5.9963 6.22811 6 6.16987 6C6.06324 6 5.95584 5.99599 5.84846 5.98793M18.1513 5.98795L18.302 6.20368C18.4365 6.39613 18.6037 6.56341 18.7962 6.69785L19.8934 7.46438C20.3793 7.80382 20.6896 8.34127 20.7406 8.93178L20.8558 10.2653C20.8761 10.4992 20.9373 10.7277 21.0367 10.9403L21.6037 12.1528C21.8548 12.6897 21.8548 13.3103 21.6037 13.8472L21.0367 15.0597C20.9373 15.2723 20.8761 15.5008 20.8558 15.7347L20.7406 17.0682C20.6896 17.6587 20.3793 18.1962 19.8934 18.5356L18.7962 19.3021C18.6037 19.4366 18.4365 19.6039 18.302 19.7963L17.5355 20.8936C17.1961 21.3794 16.6586 21.6897 16.0681 21.7408L14.7346 21.856C14.5007 21.8762 14.2722 21.9374 14.0595 22.0368L12.8471 22.6038C12.3102 22.8549 11.6896 22.8549 11.1527 22.6038L9.94022 22.0368C9.72756 21.9374 9.49906 21.8762 9.26517 21.856L7.93166 21.7408C7.34116 21.6897 6.8037 21.3794 6.46427 20.8936L5.69774 19.7963C5.56329 19.6039 5.39602 19.4366 5.20357 19.3021L4.10632 18.5356C3.62044 18.1962 3.31014 17.6587 3.25912 17.0682L3.14391 15.7347C3.1237 15.5008 3.06247 15.2723 2.96303 15.0597L2.39605 13.8472C2.14498 13.3103 2.14498 12.6897 2.39605 12.1528L2.96303 10.9403C3.06247 10.7277 3.1237 10.4992 3.14391 10.2653L3.25912 8.93177C3.31014 8.34127 3.62044 7.80382 4.10632 7.46438L5.20357 6.69785C5.39602 6.56341 5.56329 6.39613 5.69774 6.20368L5.84846 5.98793M16 13C16 15.2091 14.2091 17 12 17C9.79086 17 8 15.2091 8 13C8 10.7909 9.79086 9 12 9C14.2091 9 16 10.7909 16 13Z"
      />
      <circle cx="6.25" cy="8.75" r="0.75" fill={color}/>
      <circle cx="12" cy="19.5" r="0.75" fill={color}/>
      <circle cx="18.5" cy="12.5" r="0.75" fill={color}/>
    </svg>
  );
}

export default WreathIcon;


