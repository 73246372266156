import React from 'react';
import { Button, Text, useMantineTheme } from '@mantine/core';
import { HiArrowPath } from 'react-icons/hi2';
import BingoCardCell from './BingoCardCell';
import { randomString } from '../utils';


function BingoCard(props) {

  const mantineTheme = useMantineTheme();

  const {
    tracks,
    gameOptions,
    updatePreview,
    isOverlayVisible,
    cardNumber,
    style = {},
    scale = 1,
  } = props;

  const {
    freeSpace,
  } = gameOptions;

  const tableStyle = {
    borderCollapse: 'collapse',
    borderRadius: 3,
    border: `2px solid ${mantineTheme.colors.gray[6]}`
  };

  return (
    <>
      {(isOverlayVisible && updatePreview) &&
        <Button
          variant="default"
          leftIcon={<HiArrowPath />}
          onClick={updatePreview}
          pos='absolute'
          sx={(theme) => ({
            right: theme.spacing.md,
            top: theme.spacing.md
          })}
          style={{ zIndex: 99 }}
        >
          Shuffle tracks
        </Button>
      }
      <div style={style}>
        <table style={tableStyle}>
          <tbody>
            { tracks?.length > 0 && tracks.map((row, rowIdx) => (
                <tr key={`preview-row-${randomString()}`}>
                  { row.map((track, cellIdx) =>
                    <BingoCardCell
                      track={track.track}
                      gameOptions={gameOptions}
                      rowIdx={rowIdx}
                      cellIdx={cellIdx}
                      key={track.track.id}
                      isFreeSpace={rowIdx === 2 && cellIdx === 2 && freeSpace.showFreeSpace}
                      scale={scale}
                    />
                  )}
                </tr>
              )
            )}
          </tbody>
        </table>
        {cardNumber && <Text mt='xs' size={12 * scale} color='gray.7'>Card {cardNumber}</Text>}
      </div>
    </>
  );
}

export default BingoCard;