import React from 'react';
import { useMantineTheme } from '@mantine/core';
import FreeSpaceIcons from './FreeSpaceIcons';

function BingoCardCell(props) {

  const theme = useMantineTheme();

  const {
    track,
    gameOptions,
    isFreeSpace,
    scale,
  } = props;

  const {
    name,
    artists,
    id,
  } = track;

  const {
    textOptions,
    freeSpace,
    themeColor,
  } = gameOptions;

  const cellFontScale = textOptions.cellFontScale || 1;
  const border = `1px solid ${theme.colors.gray[6]}`;

  const cellStyle = {
    padding: 8 * scale,
    width: '20%',
    position: 'relative',
    border,
  }

  const freeSpaceStyle = {
    ...cellStyle,
    background: theme.colors.gray[1],
  }

  const cellContainerStyle = {
    aspectRatio: '1 / 1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const baseText = {
    display: 'block',
    margin: 0,
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    fontSize: (15 * cellFontScale) * scale,
    textAlign: 'center',
    color: theme.colors.gray[9],
    lineHeight: '120%',
  };

  const subduedStyle = {
    ...baseText,
    fontSize: (13 * cellFontScale) * scale,
    fontWeight: textOptions.artistsBold ? 600 : 400,    
    color: theme.colors.gray[7],
  }

  const artistStyle = {
    ...subduedStyle,
    marginTop: 6 * scale,
  };


  const getFreeSpace = () => {

    const label = <span style={subduedStyle}>{freeSpace.label}</span>;

    if (freeSpace.icon) {
      const Icon = FreeSpaceIcons.find((icon) => icon.label === freeSpace.icon)?.icon;
      if (Icon){
        return (
          <>
            <Icon
              size={40}
              color={theme.colors.gray[9]}
              style={{ marginBottom: freeSpace.label ? 6 : 0 }}
              strokeWidth={1}
            />
            {label}
          </>
        );
      }
    }
    return label;
  }

  return (
    isFreeSpace ?
      <td key={`track-${id}`} style={freeSpaceStyle}>
        <div style={cellContainerStyle}>
          {getFreeSpace()}
        </div>
      </td> :
      <td key={`track-${id}`} style={cellStyle}>
        <div style={cellContainerStyle}>
          <span style={baseText}>{name}</span>
          { textOptions.showArtists && <span style={artistStyle}>{artists[0].name}</span> }
        </div>
      </td>
  );
}

export default BingoCardCell;