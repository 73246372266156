import React from 'react';
import { Flex, Skeleton } from '@mantine/core';
import PlaylistFlexContainer from './PlaylistFlexContainer';
import { randomString } from '../utils';

function PlaylistListItemSkeleton(props) {

  const {
    avatar = false,
    p = 'xs',
    count = 5,
  } = props;

  return (
    Array(count).fill().map(() => (
      <PlaylistFlexContainer disabled p={p} key={randomString()}>
        <Flex gap='md' align='center'>
          { avatar && <Skeleton h={56} w={56} />}
          <Flex direction='column' gap='sm'>
            <Skeleton h={14} w={200} />
            <Skeleton h={14} w={150} />
          </Flex>
        </Flex>
      </PlaylistFlexContainer>
  )))
};

export default PlaylistListItemSkeleton;