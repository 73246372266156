import React from 'react';
import {
  Accordion,
  Checkbox,
  Group,
  ActionIcon,
  Text,
  Tooltip,
  Switch,
  Divider,
  TextInput,
  ScrollArea,
  Stack,
  Slider,
 } from '@mantine/core';
import { HiOutlineEyeSlash } from 'react-icons/hi2';
import FreeSpaceIcons from '../FreeSpaceIcons';

function CardsPanel(props) {

  const {
    gameOptions,
    onUpdate,
  } = props;

  const {
    freeSpace,
    textOptions,
  } = gameOptions;

  return (
    <Accordion.Item value='cards'>
      <Accordion.Control px='md'>Cards</Accordion.Control>
      <Accordion.Panel>
        <Stack spacing={4} mb='2.5rem'>
          <Text size='sm'>Font size</Text>
          <Slider
            style={{ flex: 1 }}
            color='blue'
            min={0.9}
            max={1.2}
            label={null}
            step={0.1}
            marks={[
              { value: 0.9, label: 'S' },
              { value: 1, label: 'M' },
              { value: 1.1, label: 'L' },
              { value: 1.2, label: 'XL' },
            ]}
            value={textOptions.cellFontScale}
            onChange={(val) => onUpdate('textOptions.cellFontScale', val, true)}
            onChangeEnd={(val) => onUpdate('textOptions.cellFontScale', val)}
          />
        </Stack>

        <Checkbox
          label='Show artists'
          checked={textOptions.showArtists}
          onChange={() => onUpdate('textOptions.showArtists', !textOptions.showArtists)}
          mb='md'
        />

        <Checkbox
          mb='md'
          checked={textOptions.artistsBold}
          style={{fontWeight: 600 }}
          label='Bold artist name'
          disabled={!textOptions.showArtists}
          onChange={(e) => onUpdate('textOptions.artistsBold', e.currentTarget.checked)}
        />

        <Divider my='xl' />

        <Text size='sm' mb='sm' weight={500}>Free space</Text>
        <Checkbox
          label='Show free space'
          checked={freeSpace.showFreeSpace}
          onChange={() => onUpdate('freeSpace.showFreeSpace', !freeSpace.showFreeSpace)}
          mb='md'
        />

        <TextInput
          mb='md'
          label='Label'
          placeholder='Free Space'
          disabled={!freeSpace.showFreeSpace}
          labelProps={{ style: { fontWeight: 400, color: '#000' }}}
          value={freeSpace.label}
          onChange={(e) => onUpdate('freeSpace.label', e.currentTarget.value)}
        />

        <Text size='sm' mt='md'>Icon</Text>
        <Group spacing='xs' mb='md' style={{ justifyContent: 'space-between' }}>
          <Tooltip label='No icon'>
            <ActionIcon
              disabled={!freeSpace.showFreeSpace}
              variant='subtle'
              color='red.9'
              aria-label='no icon'
              onClick={() => onUpdate('freeSpace.icon', null)}
              size={40}
            >
              <HiOutlineEyeSlash size={24} />
            </ActionIcon>
          </Tooltip>

          { FreeSpaceIcons.map((icon) => {
            const Icon = icon.icon;
            return (
              <Tooltip label={icon.name} key={icon.label}>
                <ActionIcon
                  disabled={!freeSpace.showFreeSpace}
                  variant={freeSpace.icon === icon.label ? 'filled' : 'subtle'}
                  color={freeSpace.icon !== icon.label && 'gray.7'}
                  aria-label={icon.label}
                  size={40}
                  onClick={() => onUpdate('freeSpace.icon', icon.label)}
                >
                  <Icon size={24} />
                </ActionIcon>
              </Tooltip>
            );
          })}
        </Group>
      </Accordion.Panel>
    </Accordion.Item>

  );
};

export default CardsPanel;