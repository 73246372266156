import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';
import { SiSpotify } from 'react-icons/si';

function SpotifyButton(props) {
  const { href } = props;

  return (
    <Button
      component={Link}
      to={href}
      color='spotify.0'
      size='md'
      leftIcon={
        <SiSpotify width={16} height={16} fill='currentColor' />
      }
    >
      Login with Spotify
    </Button>
  );
};

export default SpotifyButton;
