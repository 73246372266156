import React from 'react';

function ChristmasTreeIcon(props) {

  const {
    size = 24,
    color = 'currentColor',
    style,
    strokeWidth = 1.5,
  } = props;

  return (
    <svg style={style} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        d="M10 6.88L8 10.13H10L5.5 15.63H8.5L4 20.63H11V22.13H13V20.63H20L15.5 15.63H18.5L14 10.13H16L14 6.88M12 1.88L12.482 3.21661L13.9021 3.26197L12.7799 4.1334L13.1756 5.49804L12 4.7L10.8244 5.49804L11.2201 4.1334L10.0979 3.26197L11.518 3.21661L12 1.88Z"
      />
      <circle cx="9.75" cy="13.5" r="0.75" fill={color}/>
      <circle cx="15.25" cy="18.5" r="0.75" fill={color}/>
      <circle cx="12.75" cy="8.5" r="0.75" fill={color}/>
    </svg>
  );
}

export default ChristmasTreeIcon;


