import React from 'react';

function ShamrockIcon(props) {

  const {
    size = 24,
    color = 'currentColor',
    style,
    strokeWidth = 1.5,
  } = props;

  return (
    <svg style={style} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        d="M9.2184 12.0935C1.48195 15.1576 1.56799 8.69625 6.17077 7.75277C5.27016 3.3356 9.56148 2.82101 11.4885 8.73917C10.7452 3.89314 15.0481 -0.252395 16.7634 5.70859C21.5793 5.70859 21.8258 9.17257 19.2936 9.68269C15.3911 10.4689 15.7888 11.2371 14.9622 11.784C22.024 10.7119 22.8531 16.1195 17.6497 16.3871C17.8641 20.7042 13.5442 22.6962 12.3605 14.4715M15.2896 7.60938L11.6435 11.6978M11.6435 11.6978C11.6435 11.6978 13.0795 12.6044 13.8668 13.2059C14.6103 13.7739 15.5469 14.857 15.5469 14.857M11.6435 11.6978C11.6435 11.6978 10.6897 10.6796 9.89991 10.1039C9.25942 9.63694 8.12774 9.16754 8.12774 9.16754M11.6435 11.6978C11.6435 11.6978 9.84921 14.4877 9.02791 16.3508C7.52734 19.5315 7.52734 21.2326 7.52734 21.2326"
      />
    </svg>
  );
}

export default ShamrockIcon;


