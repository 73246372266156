const randomString =() => ((Math.random() + 1).toString(36).substring(8));

const updateDeep = (path, data, value) => {
  const pathArray = path.split('.');
  let current = data;

  for (let i = 0; i < pathArray.length; i += 1) {
    const key = pathArray[i];
    if (i === pathArray.length - 1) {
      current[key] = value;
    } else {
      current = current[key];
    }
  }
  return data;
}

let debounceTimeout;
const debounce = (callback, timeout) => {
  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(callback, timeout);
  return () => clearTimeout(debounceTimeout)
}

const shuffle = (array) => {
  let i = array.length;
  while (i > 0) {
    const ri = Math.floor(Math.random() * i);
    --i; // eslint-disable-line no-plusplus
    [array[i], array[ri]] = [array[ri], array[i]]; // eslint-disable-line no-param-reassign
  }
  return array;
};

const chunk = (arr, size = 5) => {
  const result = arr.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/size);
  
    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // eslint-disable-line no-param-reassign
    }
  
    resultArray[chunkIndex].push(item)
  
    return resultArray
  }, [])
  return result;
}

const slice = (arr, size = 25) => arr.slice(0,size);

const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export {
  randomString,
  updateDeep,
  debounce,
  shuffle,
  chunk,
  slice,
  urlRegex,
};

export default randomString;