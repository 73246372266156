import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AppShell, Drawer, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import GameHeader from '../components/GameHeader';
import GameSidebar from '../components/GameSidebar';
import GamePreview from '../components/GamePreview';
import TrackEditor from '../components/TrackEditor';
import GenerateModal from '../components/GenerateModal';
import ConfirmModal from '../components/ConfirmModal';

import {
  getGame,
  updateGame,
  resetPlaylist,
  uploadFile,
  generateGame,
  sanitizeGameTracks,
} from '../api';
import { updateDeep, debounce } from '../utils';

function Game() {
  const [playlist, setPlaylist] = useState(null);
  const [gameInfo, setGameInfo] = useState(null);
  const [gameOptions, setGameOptions] = useState(null);
  const [pdfInfo, setPdfInfo] = useState(null);
  const [tracks, setTracks] = useState(null);
  const [filteredTracks, setFilteredTracks] = useState(null);
  const [isGameLoading, setIsGameLoading] = useState(true);
  const [isGameSaving, setIsGameSaving] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [editedTrack, setEditedTrack] = useState({ trackIndex: 0, track: {} });
  const [trackSearchQuery, setTrackSearchQuery] = useState('');
  // const debouncedTrackSearchQuery = useDebouncedValue(trackSearchQuery, 400);

  const { gameId } = useParams();
  const navigate = useNavigate();
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const [resyncModalOpened, { open: openResyncModal, close: closeResyncModal }] = useDisclosure(false);
  const [drawerOpen, { open: openDrawer, close: closeDrawer }] = useDisclosure(false);

  const saveGameInfo = (data) => {
    const newData = {...data};
    delete newData.tracks;
    delete newData.gameOptions;
    delete newData.playlist;
    setGameInfo(newData);
  }

  const resetTracks = () => {
    closeResyncModal()
    setIsGameLoading(true);
    setTracks(null);

    resetPlaylist(gameId)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setTracks(data.tracks);
        setIsGameLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching playlist:', error); // eslint-disable-line
      });
  }

  const fetchGame = () => {
    getGame(gameId)
      .then((response) => {
        if (!response.ok) {
          navigate('/games');
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const gameData = data;
        setTracks(gameData.tracks);
        setGameOptions(gameData.gameOptions);
        setPlaylist(gameData.playlist);
        saveGameInfo(gameData);
        setIsGameLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching playlist:', error); // eslint-disable-line
      });
  }

  const updateGameValue = (field, value) => {
    if (field) {
      setIsGameSaving(true);
      const payload = {
        [field]: value,
      };
      updateGame(gameId, payload)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          saveGameInfo(data);
          closeDrawer();
          setTimeout(() => setIsGameSaving(false), 400);
        })
        .catch((error) => {
          console.error('Error fetching playlist:', error); // eslint-disable-line
        });
    }
  }

  const editTrack = (trackObj) => {
    const trackIndex = tracks.findIndex(({track}) => track.id === trackObj.id);
    setEditedTrack({trackIndex, track: tracks[trackIndex].track });
    openDrawer();
  }

  const sanitizeTracks = () => {
    setIsGameLoading(true);
    sanitizeGameTracks(gameId)
    .then((response) => {
      if (!response.ok) {
        setIsGameLoading(false);
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      setTracks(data.tracks);
      setIsGameLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching playlist:', error); // eslint-disable-line
      setIsGameLoading(false);
    });
  }

  const saveTrack = (trackIndex, track) => {
    setIsGameSaving(true)
    let newData = [ ...tracks ];
        newData = updateDeep(`${trackIndex}.track.isFlagged`, newData, false);
        newData = updateDeep(`${trackIndex}.track.name`, newData, track.name);
        newData = updateDeep(`${trackIndex}.track.artists.0.name`, newData, track.artist);
    setTracks(newData);
    updateGameValue(`tracks.${trackIndex}`, newData[trackIndex]);
  }

  const saveGameOptions = (field, value, stateOnly = false) => {
    let newData = { ...gameOptions };
    newData = updateDeep(field, newData, value);
    setGameOptions(newData); 
    if (!stateOnly) {
      debounce(() => updateGameValue(`gameOptions.${field}`, value), 400);
    }
  }

  const saveUploadedFile = (file) => {
    setIsFileLoading(true);
    setIsGameSaving(true);
    uploadFile(gameId, file)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setGameOptions(data.gameOptions);
        saveGameInfo(data);
        closeDrawer();
        setTimeout(() => {
          setIsGameSaving(false);
          setIsFileLoading(false);
        }, 400);
      })
      .catch((error) => {
        console.error('Error fetching playlist:', error); // eslint-disable-line
      });
  }


  const pollForUpdates = async () => {
    getGame(gameId)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.status === 'IN_PROGRESS') {
        setTimeout(pollForUpdates, 3000); // Poll every 3 seconds
      } else if (data.status === 'IDLE' && data.pdfUrl?.length > 0) {
        setPdfInfo(data.pdfUrl);
        setIsGenerating(false);
        saveGameInfo(data);
      }
    })
    .catch((error) => {
      console.error('Error fetching playlist:', error); // eslint-disable-line
    });
  };

  const generateCards = () => {
    setIsGenerating(true);
    setPdfInfo(null);
    generateGame(gameId)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(() => {
      pollForUpdates();
    })
    .catch((error) => {
      console.error('Error fetching playlist:', error); // eslint-disable-line
    });
  }


  useEffect(() => {
    if (gameId) {
      fetchGame();
    }
  }, [gameId]);

  useEffect(() => {
    if (trackSearchQuery && trackSearchQuery?.length > 1){
      const newFilteredTracks = tracks.filter((track) =>
        track.track.name?.toLowerCase().includes(trackSearchQuery.toLowerCase()) ||
        track.track.artists[0]?.name.toLowerCase().includes(trackSearchQuery.toLowerCase())
      );
      setFilteredTracks(newFilteredTracks);
    }
  }, [trackSearchQuery])

  return (
    <div>
      <AppShell
        padding='md'
        navbar={
          <GameSidebar
            isLoading={isGameLoading}
            isFileLoading={isFileLoading}
            onUpdate={saveGameOptions}
            onFileUpload={saveUploadedFile}
            tracks={ trackSearchQuery?.length > 1 ? filteredTracks : tracks}
            gameOptions={gameOptions}
            editTrack={editTrack}
            playlist={playlist}
            resetTracks={openResyncModal}
            onSearch={setTrackSearchQuery}
            searchQuery={trackSearchQuery}
            sanitizeTracks={sanitizeTracks}
            flaggedLength={tracks?.filter(({track}) => track.isFlagged).length}
          />
        }
        header={
          <GameHeader
            isLoading={isGameLoading}
            playlist={playlist}
            gameOptions={gameOptions}
            updatedAt={gameInfo?.updatedAt}
            pdfUrl={gameInfo?.pdfUrl}
            generatedAt={gameInfo?.generatedAt}
            isSaving={isGameSaving}
            onSubmit={(e) => {
              setPdfInfo(null);
              openModal(e);
            }}
          />
        }
        styles={(theme) => ({
          main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
        })}
      >

        <GamePreview tracks={tracks} gameOptions={gameOptions} isLoading={isGameLoading} />
        <Drawer opened={drawerOpen} onClose={closeDrawer} title='Edit track' size={300}>
          <TrackEditor
            track={editedTrack.track}
            trackIndex={editedTrack.trackIndex}
            onSubmit={saveTrack}
            isLoading={isGameLoading}
          />
        </Drawer>

        <GenerateModal
          opened={modalOpened}
          close={closeModal}
          isLoading={isGameLoading}
          onUpdate={saveGameOptions}
          gameOptions={gameOptions}
          gameInfo={gameInfo}
          isGenerating={isGenerating}
          onSubmit={generateCards}
          pdfInfo={pdfInfo}
        />

        <ConfirmModal
          opened={resyncModalOpened}
          onClose={closeResyncModal}
          title='Resync with Spotify?'
          message='This will fetch the playlist from Spotify again, and overwrite any customizations.'
          submitText='Reset tracks'
          submitColor='red.9'
          onSubmit={resetTracks}
        />

      </AppShell>

    </div>
  );
}

export default Game;
