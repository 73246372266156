import React, { useEffect } from 'react';
import { Title, Text, Container, Box } from '@mantine/core';
import { useParams, Navigate } from 'react-router-dom';
import SpotifyButton from '../components/SpotifyButton';
import { useAuth } from '../Auth';

const spotifyAuthUrl = `${process.env.REACT_APP_API_HOST}/auth/spotify`;

function LoginRoute() {
  const { isAuthenticated, login } = useAuth();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      login(token);
    }
  }, [login, token]);


  return (
    isAuthenticated ?
      <Navigate to='/games' replace /> :
      <Box bg='gray.1'>
        <Container py={200}>
          <Title mb={4}>Spotify music bingo</Title>
          <Text mb='xl'>Create customized games from any playlist.</Text>
          <SpotifyButton href={spotifyAuthUrl} />
        </Container>
      </Box>
  );

}

export default LoginRoute;


