import React from 'react';
import { Flex, Avatar, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import PlaylistFlexContainer from './PlaylistFlexContainer';

function PlaylistListItem(props) {
  const { hovered, ref } = useHover();
  const {
    image,
    title,
    subtitle,
    hoverContent,
    onClick,
  } = props;

  return (
    <PlaylistFlexContainer offset rounded ref={ref} onClick={onClick}>
      <Flex gap='md' align='center'>
        <Avatar size="lg" imageProps={{ loading: 'lazy' }} src={image} alt={`Artwork for the playlist ${title}`} />
        <Flex direction='column' flex={1}>
          <Text fw={500} c='gray.9'>{title}</Text>
          <Text fz='sm' c='gray.7'>{subtitle}</Text>
        </Flex>
      </Flex>
      {hovered && hoverContent}
    </PlaylistFlexContainer>
  );
};

export default PlaylistListItem;
