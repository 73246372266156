import React from 'react';
import { 
  Anchor,
  Accordion,
  TextInput,
  Checkbox,
  Textarea,
  Divider,
  Text,
  Stack,
  Button,
  ScrollArea,
  Group,
} from '@mantine/core';

import {
  HiOutlineBars3BottomLeft,
  HiOutlineBars3BottomRight,
  HiOutlineBars3,
} from 'react-icons/hi2';

import FileUpload from '../FileUpload';

function FooterPanel(props) {

  const {
    playlist,
    gameOptions,
    onUpdate,
    onFileUpload,
    isFileLoading,
  } = props;

  const {
    qrCode,
    footer
  } = gameOptions;

  const alignmentOptions = [
    { icon: <HiOutlineBars3BottomLeft />, value: 'left' },
    { icon: <HiOutlineBars3 />, value: 'center' },
    { icon: <HiOutlineBars3BottomRight />, value: 'right' },
  ]

  return (
    <Accordion.Item value='footer'>
      <Accordion.Control px='md'>Footer</Accordion.Control>
      <Accordion.Panel>
        <Checkbox
          label='Show shapes'
          checked={footer.showShapes}
          onChange={() => onUpdate('footer.showShapes', !footer.showShapes)}
          mb='md'
        />

        <Checkbox
          label='Show featured image'
          checked={footer.showFeaturedImage}
          onChange={() => onUpdate('footer.showFeaturedImage', !footer.showFeaturedImage)}
          mb='md'
        />

        <FileUpload
          disabled={!footer.showFeaturedImage}
          onUpdate={onFileUpload}
          featuredImageUrl={footer.featuredImageUrl}
          isLoading={isFileLoading}
        />

        <Textarea
          mb='md'
          label='Message'
          placeholder='Follow me on Spotify'
          labelProps={{ style: { fontWeight: 400, color: '#000' }}}
          value={footer.message}
          onChange={(e) => onUpdate('footer.message', e.currentTarget.value)}
          minRows={2}
          maxRows={2}
        />

        <Stack spacing={4} mb='md'>
          <Text size='sm'>Alignment</Text>
          <Button.Group w='100%'>
            { alignmentOptions.map((option) => (
              <Button
                key={option.value}
                fullWidth
                variant={option.value === footer.messageAlign ? 'filled' : 'default'}
                onClick={(val) => onUpdate('footer.messageAlign', option.value)}
              >
                {option.icon}
              </Button>
            )) }
          </Button.Group>

        </Stack>

        <Divider my='xl' />

        <Text size='sm' mb='sm' weight={500}>QR code</Text>

        <Checkbox
          label='Show QR code'
          checked={qrCode.showQrCode}
          onChange={() => onUpdate('qrCode.showQrCode', !qrCode.showQrCode)}
          mb='md'
        />

        <TextInput
          disabled={!qrCode.showQrCode}
          label={
            <Group align='center' style={{ justifyContent: 'space-between' }}>
              URL
              <Anchor onClick={() => onUpdate('qrCode.url', playlist?.external_urls?.spotify)} size='sm'>
                Set to playlist URL
              </Anchor>
            </Group>
          }
          type='url'
          placeholder='https://youtu.be/dQw4w9WgXcQ'
          labelProps={{ style: { fontWeight: 400, color: '#000', width: '100%' }}}
          value={qrCode.url}
          onChange={(e) => onUpdate('qrCode.url', e.currentTarget.value)}
          mb='lg'
        />

      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default FooterPanel;