import React from 'react';
import { Menu, Avatar } from '@mantine/core';
import { HiOutlineArrowRightOnRectangle } from 'react-icons/hi2';
import { useAuth } from '../Auth';

function UserMenu() {
  const { user, logout } = useAuth();

  return (
    <Menu withArrow>
      <Menu.Target>
        <Avatar src={user?.data?.profile_image} radius='xl' />
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          icon={<HiOutlineArrowRightOnRectangle />}
          color="red.9"
          onClick={() => logout()}
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export default UserMenu;