import React, { useEffect } from 'react';
import { Select } from '@mantine/core';
import { fonts } from './googleFonts'; // pre-loaded in App.js

function FontSelector(props) {
    const {
        onUpdate,
        selectedFont,
        disabled,
    } = props;

    // useEffect(() => {
    //   loadFonts();
    // }, [fonts]);

  return (
    <Select
      label='Font'
      labelProps={{ style: { fontWeight: 400, color: '#000' }}}
      placeholder='Pick font'
      data={fonts}
      value={selectedFont}
      onChange={onUpdate}
      disabled={disabled}
    />
  );
};

FontSelector.defaultProps = {
  disabled: false,
}

export default FontSelector;
