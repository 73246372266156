import React, { useState } from 'react';
import {
  Header,
  Flex,
  ActionIcon,
  Button,
  Text,
  Stack,
  Skeleton,
  Group,
  Tooltip,
  Anchor
} from '@mantine/core';
import { formatRelative } from 'date-fns';
import { Link } from 'react-router-dom';
import { SiSpotify } from 'react-icons/si';
import { HiOutlineChevronLeft, HiOutlineArrowDownTray, HiOutlineRocketLaunch } from 'react-icons/hi2';
import PlaylistArtwork from './PlaylistArtwork';

function GameHeader(props) {

  const {
    isLoading,
    gameOptions,
    playlist,
    updatedAt,
    pdfUrl,
    onSubmit,
    generatedAt,
    isSaving,
  } = props;

  const formattedUpdatedAt = updatedAt ? formatRelative(new Date(updatedAt), new Date()) : null;
  const formattedGeneratedAt = generatedAt ? formatRelative(new Date(generatedAt), new Date()) : null;  

  return (
    <Header height={72}>
      <Flex justify='space-between' align='center' p='sm'>      
        <Flex direction='row' align='center' gap='xs'>
          <Link to='/games' style={{ textDecoration: 'none' }}>
            <ActionIcon size='md'>
              <HiOutlineChevronLeft color='gray.9' />
            </ActionIcon>
          </Link>


          <Flex gap='md' direction='row' align='center' h={48}>
            <PlaylistArtwork playlist={playlist} />

            {!(gameOptions?.title && updatedAt) ? 
              <Stack spacing={4}>
                <Skeleton h={20} w={200} />
                <Skeleton h={12} w={160} />
              </Stack> 
            : 
              <Stack spacing='none'>
                <Text size='md' weight={500}>{gameOptions?.title}</Text>
                <Anchor
                  component={Link}
                  to={playlist?.external_urls?.spotify}
                  target='_blank'
                  size='sm'
                  c='dimmed'
                  style={{
                    display: 'flex',
                    gap: 6,
                    alignItems: 'center'
                  }}
                >
                  <SiSpotify size={16} fill='currentColor' />
                  Open in Spotify
                </Anchor>

              </Stack>
            }
          </Flex>
        </Flex>
        
        <Group spacing='xl'>  
          { formattedUpdatedAt && <Text size='sm' c='dimmed'>Updated {formattedUpdatedAt}</Text>}
          <Group>
            <Button
              variant={pdfUrl ? 'light' : 'filled' }
              disabled={isLoading}
              onClick={onSubmit}
              leftIcon={<HiOutlineRocketLaunch size={20} />}
              loading={isSaving}
            >
              {pdfUrl ? 'Regenerate' : 'Generate' }{' '}cards
            </Button>

            { pdfUrl &&
              <Tooltip label={`Generated ${formattedGeneratedAt}`} position='bottom'>
                <Button component='a' href={pdfUrl} target='_blank' leftIcon={<HiOutlineArrowDownTray size={20} />}>
                  Download game
                </Button>
              </Tooltip>
            }
          </Group>

        </Group>
      </Flex>
    </Header>
  );
}

export default GameHeader;