import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Title, Text, TextInput, Badge, Group, Paper, Alert, ActionIcon, Button } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { SiSpotify } from 'react-icons/si';
import { HiOutlineExclamationCircle, HiOutlineXCircle, HiOutlineArrowDown } from 'react-icons/hi2';

import { getPlaylists } from '../api';
import { useAuth } from '../Auth';
import PlaylistListItem from './PlaylistListItem';
import PlaylistListItemSkeleton from './PlaylistListItemSkeleton';
import GameShell from './GameShell';
import { randomString } from '../utils';

function Playlists() {
  const { user } = useAuth();
  const TIMEOUT_DURATION = 800;

  const [playlists, setPlaylists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingInline, setIsLoadingInline] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [userId, setUserId] = useState(user.data.username);
  const [debouncedUserId] = useDebouncedValue(userId, TIMEOUT_DURATION);
  const [page, setPage] = useState(1);

  // const setNewPlaylists = (newPlaylists) => {
  //   const oldPlaylists = playlists || [];
  //   const data = [
  //     ...oldPlaylists,
  //     ...newPlaylists,
  //   ];
  //   setPlaylists(data);
  // }

  function loadPlaylists(id, pageNumber) { 
    if (pageNumber === 1) {
      setIsLoading(true);
    } else {
      setIsLoadingInline(true);
    }
    setHasError(false);

    setUserId(id);
    setPage(pageNumber);

    getPlaylists(id, pageNumber)
      .then((response) => {
        if (!response.ok) {
          setPlaylists([]);
          setHasError(true);
          setIsLoading(false);
          setIsLoadingInline(false);
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data?.playlists?.length){
          setTimeout(() => {
            const newPlaylist = pageNumber > 1 ? [...playlists, ...data.playlists] : data.playlists;
            setPlaylists(newPlaylist);
            setHasError(false);
            setIsLoading(false);
            setIsLoadingInline(false);
          }, TIMEOUT_DURATION)
        } else {
          setHasError(true);
          setIsLoading(false);
          setIsLoadingInline(false);
        }
      })
      .catch((error) => {
        setPlaylists([]);
        setHasError(true);
        setIsLoading(false);
        setIsLoadingInline(false);
        console.error('Error fetching playlists:', error); // eslint-disable-line
      });
  }

  const resetUsername = () => {
    setIsLoading(true);
    setHasError(false);
    setUserId(user.data.username);
  }

  const nextPage = () => {
    loadPlaylists(userId, page + 1);
  }

  useEffect(() => {
    if (userId.length) {
      loadPlaylists(userId, 1);
    }
  }, [debouncedUserId]);

  return (
    <GameShell>
      <Container size='xs' py='lg'>
        <Title order={1} fw={500}>New game</Title>
        <Group align='center' justify='center' mt='xs' mb='lg' spacing='sm'>
          <Text>Library for</Text>
          <TextInput
            miw={56}
            value={userId}
            icon={<SiSpotify />}
            onChange={(e) => setUserId(e.currentTarget.value)}
            rightSection={(user.data.username !== userId) &&
              <ActionIcon onClick={resetUsername}>
                <HiOutlineXCircle size={20} />
              </ActionIcon>
            }
          />
        </Group>

        <Paper shadow='lg' p='md'>
          {(!isLoading && !hasError) && playlists.map((playlist) => {

            const {
              images,
              name,
              owner,
            } = playlist;

            const trackCount = playlist.track_count; /* eslint-disable-line camelcase */
            
            return (
              <Link
                to={`/games/${playlist.id}`}
                key={`${playlist.id}-${randomString()}`}
                style={{ textDecoration: 'none' }}
              >
                <PlaylistListItem
                  isLoading={isLoading}
                  image={images[0]?.url}
                  title={name}
                  subtitle={`Playlist by ${owner?.display_name}`}
                  hoverContent={<Badge>{trackCount} tracks</Badge>}
                />

              </Link>
            )
          })}
          {isLoading && <PlaylistListItemSkeleton count={10} avatar />}
          {hasError && 
            <Alert
              icon={<HiOutlineExclamationCircle />}
              color='gray'
              title='No playlists found'
            >
              Create Spotify playlists to see them here, or search for a different user.
            </Alert>
          }
        </Paper>

        <Group my='lg' style={{ justifyContent: 'center' }}>
          <Button
            onClick={nextPage}
            leftIcon={<HiOutlineArrowDown />}
            variant='subtle'
            loading={isLoadingInline}
          >  
            Load more
          </Button>
        </Group>


      </Container>
    </GameShell>
  );
}

export default Playlists;
