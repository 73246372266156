import LineShape from './line.svg';
import CornersShape from './corners.svg';
import XShape from './x.svg';
import LShape from './l.svg';
import TShape from './t.svg';
import HShape from './h.svg';
import SixShape from './six.svg';
import NineShape from './nine.svg';
import PlusShape from './plus.svg';
import DiamondShape from './diamond.svg';
import FrameShape from './frame.svg';
import FullShape from './full.svg';

const shapes = [
 { name: 'line', src: LineShape },
 { name: 'corners', src: CornersShape  },
 { name: 'x', src: XShape },
 { name: 'l', src: LShape },
 { name: 't', src: TShape },
 { name: 'h', src: HShape },
 { name: 'six', src: SixShape },
 { name: 'nine', src: NineShape },
 { name: 'plus', src: PlusShape },
 { name: 'diamond', src: DiamondShape },
 { name: 'frame', src: FrameShape },
 { name: 'full', src: FullShape },
];

export default shapes;