import React from 'react';
import {
  Navbar,
  Badge,
  Button,
  Tabs,
  ScrollArea,
  Flex,
  Text,
  Divider,
  Tooltip,
  ActionIcon,
  TextInput,
  Stack,
  Group,
  Alert,
} from '@mantine/core';
import {
  HiOutlineExclamationTriangle,
  HiOutlineExclamationCircle,
  HiOutlineMagnifyingGlass,
  HiOutlineXCircle,
  HiOutlineSparkles,
  HiOutlineArrowPath,
} from 'react-icons/hi2';
import PlaylistFlexContainer from './PlaylistFlexContainer';
import PlaylistListItemSkeleton from './PlaylistListItemSkeleton';
import GameEditor from './GameEditor';
import { randomString } from '../utils';

function GameSidebar(props) {
  const { 
    resetTracks,
    tracks,
    editTrack,
    gameOptions,
    onUpdate,
    isLoading,
    isFileLoading,
    onFileUpload,
    playlist,
    searchQuery,
    onSearch,
    sanitizeTracks,
    flaggedLength
  } = props;

  const getBadge = () => flaggedLength > 0 && <Badge color='orange'>{flaggedLength}</Badge>;

  const getHighlightedText = (text) => {
    if (searchQuery) {
      // Split on highlight term and include term into parts, ignore case
      const chars = text.split(new RegExp(`(${searchQuery})`, 'gi'));
      return <Text> { chars.map((char) => {
        const matchesString = char.toLowerCase() === searchQuery.toLowerCase();
          return (
            <Text
              key={randomString()}
              fw={matchesString && 700}
              bg={matchesString && 'yellow.1'}
              display='inline'
            >
              {char}
            </Text>
          )
        })
      } </Text>;
    }
    return text;
}
 
  return (
      <Navbar width={{ base: 300 }}>   
        <Tabs defaultValue='tracks'>
          <Tabs.List>
            <Tabs.Tab value='tracks' p='md' h={48} rightSection={getBadge()}>Tracks</Tabs.Tab>
            <Tabs.Tab value='design' p='md' h={48}>Design</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='tracks'>
            <Flex direction='column' pos='absolute' h='calc(100% - 48px)' w='100%'>

              <Stack p='md'>
                <TextInput
                  w='100%'
                  value={searchQuery}
                  placeholder='Artist or track name...'
                  icon={<HiOutlineMagnifyingGlass />}
                  onChange={(e) => onSearch(e.currentTarget.value)}
                  rightSection={searchQuery?.length > 1 &&
                    <ActionIcon onClick={() => onSearch('')}>
                      <HiOutlineXCircle size={20} />
                    </ActionIcon>
                  }
                />

                {(tracks?.length > 0 && flaggedLength !== 0) && 
                  <Alert
                    icon={<HiOutlineSparkles size={24} />}
                    color='violet'
                    w='100%'
                    title='Cleaner track names'
                  >
                    Use AI to remove things like &quot;radio edit&quot; and &quot;remaster&quot; from song titles.<br/>
                    <Button
                      mt='xs'
                      variant='filled'
                      color='violet'
                      loading={isLoading}
                      onClick={sanitizeTracks}
                    >
                      Update {flaggedLength} tracks
                    </Button>
                  </Alert>
                }

              </Stack>

              <Divider color='gray.3' />
              
              <ScrollArea style={{ flex: 1 }}>
                {(!isLoading && tracks?.length > 0) && tracks.map(({track}) => track &&
                  <React.Fragment key={`${track.name}-${randomString()}`}>
                    <PlaylistFlexContainer p='md' onClick={() => editTrack(track)}>
                      <Flex direction='column' flex={1}>
                        <Text fw={500} c='gray.9'>{getHighlightedText(track.name)}</Text> 
                        <Text fz='sm' c='gray.7'>{ (track.artists && track.artists.length > 0) && getHighlightedText(track.artists[0].name)}</Text>
                      </Flex>

                      {track.isFlagged &&  
                        <Tooltip label='Issues with track name'>
                          <ActionIcon variant='transparent' color='yellow.9'>
                            <HiOutlineExclamationTriangle size={20} />
                          </ActionIcon>
                        </Tooltip>
                      }
                    </PlaylistFlexContainer>
                    <Divider color='gray.3' />
                  </React.Fragment>
                )}
                { (!isLoading && !tracks?.length) && 
                  <Group p='md'>
                    <Alert
                      icon={<HiOutlineExclamationCircle size={24} />}
                      color='gray'
                      w='100%'
                      title='No tracks found'
                    >
                      Clear your search query and try again.
                    </Alert>
                  </Group>
                }
                { isLoading && <PlaylistListItemSkeleton count={15} p='md' /> }
                { !isLoading && 
                  <Stack w='100%' py='lg' px='md' spacing='xs'>
                    <Button
                      fullWidth
                      variant='light'
                      leftIcon={<HiOutlineArrowPath size={20} />}
                      color='red.9'
                      onClick={resetTracks}
                    >
                      Reset tracks
                    </Button>
                  </Stack>
                }
              </ScrollArea>
            </Flex>
          </Tabs.Panel>

          <Tabs.Panel value='design'>
            <Flex pos='absolute' h='calc(100% - 48px)' w='100%'>
            { gameOptions ? 
            <ScrollArea w='100%'>
              <GameEditor
                onUpdate={onUpdate}
                gameOptions={gameOptions}
                isLoading={isLoading}
                onFileUpload={onFileUpload}
                isFileLoading={isFileLoading}
                playlist={playlist}
              />
              </ScrollArea> : 
              <PlaylistListItemSkeleton p='md' />
            }
            </Flex>
          </Tabs.Panel>
          

        </Tabs>
      </Navbar>
  );
}

export default GameSidebar;