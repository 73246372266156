import React from 'react';

function ChampagneIcon(props) {

  const {
    size = 24,
    color = 'currentColor',
    style,
    strokeWidth = 1.5,
  } = props;

  return (
    <svg style={style} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        d="M4.81126 11.2963C5.51619 6.80974 7.05022 5.13278 7.05022 5.13278L11.0454 6.1705C11.0454 6.1705 11.509 8.3663 9.80528 12.5934M4.81126 11.2963C4.10632 15.7829 5.70252 17.754 5.70252 17.754M4.81126 11.2963C4.81126 11.2963 5.44884 10.9431 6.4433 11.2014C6.92989 11.3277 7.67616 11.9135 8.38202 12.2238C9.11875 12.5476 9.80528 12.5934 9.80528 12.5934M5.70252 17.754C5.70252 17.754 8.10158 16.8206 9.80528 12.5934M5.70252 17.754L4.49819 22.1109M4.49819 22.1109L5.99638 22.5M4.49819 22.1109L3 21.7217M14.1947 12.5934C12.491 8.3663 12.9546 6.1705 12.9546 6.1705L16.9498 5.13278C16.9498 5.13278 18.4838 6.80974 19.1888 11.2963M14.1947 12.5934C15.8984 16.8206 18.2975 17.754 18.2975 17.754M14.1947 12.5934C14.1947 12.5934 14.5647 11.9785 15.5592 11.7202C16.0457 11.5938 16.9942 11.7394 17.7655 11.666C18.5705 11.5893 19.1888 11.2963 19.1888 11.2963M18.2975 17.754C18.2975 17.754 19.8937 15.7829 19.1888 11.2963M18.2975 17.754L19.5018 22.1109M19.5018 22.1109L21 21.7217M19.5018 22.1109L18.0036 22.5M10.4792 3.481L8.95841 1.99525M12 3.481V1.5M13.5208 3.481L15.0416 1.99525"
      />
    </svg>
  );
}

export default ChampagneIcon;


