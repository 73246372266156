import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Flex,
  Container,
  Badge,
  Title,
  Paper,
  Button,
  Skeleton,
  Group,
} from '@mantine/core';
import { HiOutlineArrowDownTray } from 'react-icons/hi2';
import { formatRelative } from 'date-fns';
import { getAllGames } from '../api';
import PlaylistListItem from '../components/PlaylistListItem';
import PlaylistListItemSkeleton from '../components/PlaylistListItemSkeleton';
import GameShell from '../components/GameShell';

function GamesList() {
  const [isLoading, setIsLoading] = useState(true);
  const [games, setGames] = useState(null);
  const navigate = useNavigate();

  const fetchGames = () => {
    getAllGames()
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0 ) {
          navigate('/games/new');
        }
        setGames(data);
        setTimeout(() => setIsLoading(false), 400);
      })
      .catch((error) => {
        console.error('Error fetching playlist:', error); // eslint-disable-line
      });
  }

  const getPdf = (e, pdfUrl) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(pdfUrl, '_blank');
  }
  
  const getSubtitle = (game) => {
    const {
      pdfUrl,
      generatedAt,
      updatedAt,
    } = game;

    if (pdfUrl && generatedAt) {
      const formattedGeneratedAt = formatRelative(new Date(generatedAt), new Date());
      return `Generated ${formattedGeneratedAt}`;
    }

    const formattedUpdatedAt = formatRelative(new Date(updatedAt), new Date());
    return `Updated ${formattedUpdatedAt}`;
  }

  useEffect(() => {
    fetchGames();
  }, []);

  return (
      <GameShell showBackNavigation={false}>
        <Container size='xs' py='lg'>
          <Flex justify='space-between' align='center' mb='lg'>
            <Title order={1} fw={500}>Your games</Title>
            <Link to='/games/new' style={{ textDecoration: 'none' }}>
              <Button>New game</Button>
            </Link>
          </Flex>

          <Paper shadow='lg' p='md'>
            {(!isLoading) && games.map((game) => {
              const {
                title,
                playlist,
                playlistId,
                pdfUrl,
              } = game;

              return (
                <PlaylistListItem
                  key={playlistId} 
                  isLoading={isLoading}
                  image={playlist?.images[0]?.url}
                  title={title}
                  subtitle={getSubtitle(game)}
                  onClick={() => navigate(`/games/${playlistId}`)}
                  hoverContent={
                    <Group spacing='md'>
                      <Button
                        disabled={!pdfUrl}
                        onClick={(e) => getPdf(e, pdfUrl)}
                        leftIcon={<HiOutlineArrowDownTray size={20} />}
                        variant='light'
                      >
                        Download
                      </Button>
                    </Group>
                  }
                />
              )
            })}
            {isLoading && <PlaylistListItemSkeleton avatar />}
          </Paper>
        </Container>
      </GameShell>
  );
}

export default GamesList;