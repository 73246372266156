import React from 'react';

function HorseshoeIcon(props) {

  const {
    size = 24,
    color = 'currentColor',
    style,
    strokeWidth = 1.5,
  } = props;

  return (
    <svg style={style} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        d="M12 2C5 2 2 6.47715 2 12C2 17.5228 3.87324 16.3803 3 19C2.72386 19.8284 2 20.1268 2 21C2 22.9526 9 21.9526 9 20C9 17.5 3 7 12 7C21 7 15 17.5 15 20C15 21.9526 22 22.9526 22 21C22 20.1268 21.2761 19.8284 21 19C20.1268 16.3803 22 17.5228 22 12C22 6.47715 19 2 12 2Z"
      />
      <circle cx="5.5" cy="17.25" r="0.75" fill={color}/>
      <circle cx="18.5" cy="17.25" r="0.75" fill={color}/>
      <circle cx="5" cy="9.25" r="0.75" fill={color}/>
      <circle cx="19" cy="9.25" r="0.75" fill={color}/>
      <circle cx="12" cy="4.75" r="0.75" fill={color}/>
    </svg>
  );
}

export default HorseshoeIcon;


