import React from 'react';

function SkullIcon(props) {

  const {
    size = 24,
    color = 'currentColor',
    style,
    strokeWidth = 1.5,
  } = props;

  return (
    <svg style={style} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.6673 17.6714V20.338M13.3327 17.6714V20.338M20 11.0045C20.0007 9.63365 19.6492 8.28561 18.9792 7.08968C18.3091 5.89375 17.343 4.89006 16.1735 4.17491C15.004 3.45976 13.6703 3.05713 12.3004 3.00564C10.9305 2.95416 9.57041 3.25555 8.35052 3.88091C7.13062 4.50627 6.09188 5.4346 5.33396 6.57686C4.57603 7.71912 4.12436 9.03698 4.02223 10.404C3.92011 11.7711 4.17099 13.1414 4.75079 14.3836C5.3306 15.6258 6.21988 16.6982 7.33332 17.4978V19.0044C7.33332 19.3581 7.4738 19.6972 7.72385 19.9472C7.97389 20.1973 8.31303 20.3378 8.66665 20.3378H15.3333C15.6869 20.3378 16.026 20.1973 16.276 19.9472C16.5261 19.6972 16.6667 19.3581 16.6667 19.0044V17.4978C17.6974 16.7575 18.5374 15.7825 19.1169 14.6534C19.6965 13.5244 19.9992 12.2736 20 11.0045ZM7.33088 12.0033C7.33088 12.4454 7.50651 12.8694 7.81912 13.182C8.13173 13.4946 8.55572 13.6703 8.99782 13.6703C9.43992 13.6703 9.86391 13.4946 10.1765 13.182C10.4891 12.8694 10.6648 12.4454 10.6648 12.0033C10.6648 11.5612 10.4891 11.1372 10.1765 10.8246C9.86391 10.512 9.43992 10.3364 8.99782 10.3364C8.55572 10.3364 8.13173 10.512 7.81912 10.8246C7.50651 11.1372 7.33088 11.5612 7.33088 12.0033ZM13.3327 12.0033C13.3327 12.2222 13.3758 12.439 13.4596 12.6412C13.5434 12.8435 13.6662 13.0272 13.821 13.182C13.9757 13.3368 14.1595 13.4596 14.3617 13.5434C14.564 13.6272 14.7808 13.6703 14.9997 13.6703C15.2186 13.6703 15.4353 13.6272 15.6376 13.5434C15.8398 13.4596 16.0236 13.3368 16.1784 13.182C16.3331 13.0272 16.4559 12.8435 16.5397 12.6412C16.6235 12.439 16.6666 12.2222 16.6666 12.0033C16.6666 11.7844 16.6235 11.5677 16.5397 11.3654C16.4559 11.1632 16.3331 10.9794 16.1784 10.8246C16.0236 10.6698 15.8398 10.5471 15.6376 10.4633C15.4353 10.3795 15.2186 10.3364 14.9997 10.3364C14.7808 10.3364 14.564 10.3795 14.3617 10.4633C14.1595 10.5471 13.9757 10.6698 13.821 10.8246C13.6662 10.9794 13.5434 11.1632 13.4596 11.3654C13.3758 11.5677 13.3327 11.7844 13.3327 12.0033Z"
      />
    </svg>
  );
}

export default SkullIcon;


