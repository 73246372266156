import React from 'react';

function CandyCaneIcon(props) {

  const {
    size = 24,
    color = 'currentColor',
    style,
    strokeWidth = 1.5,
  } = props;

  return (
    <svg style={style} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        d="M14.1749 19.905L16.4736 22.2037C17.1571 22.8871 18.2645 22.8865 18.9479 22.2031L19.1247 22.0263C19.7105 21.4405 19.7105 20.4907 19.1247 19.905M14.1749 19.905H19.1247M14.1749 19.905L11.7001 17.4301M19.1247 19.905L16.6498 17.4301M16.6498 17.4301L11.7001 17.4301M16.6498 17.4301L14.1749 14.9552M11.7001 17.4301L9.22519 14.9552M14.1749 14.9552H9.22519M14.1749 14.9552L11.7001 12.4803M9.22519 14.9552L6.75032 12.4803M11.7001 12.4803H6.75032M11.7001 12.4803L9.22519 10.0055M6.75032 12.4803L6.39677 12.1268C6.11831 11.8483 5.456 11.0765 4.99287 10.0055M9.22519 10.0055L8.87164 9.65191C8.34293 9.1232 8.01202 8.39669 8.46977 7.47269M9.22519 10.0055H4.99287M4.99287 10.0055C4.6786 9.27872 4.45604 8.41423 4.50737 7.47269M8.46977 7.47269C8.62422 7.16092 8.86846 6.82666 9.22519 6.46993C9.90968 5.78545 10.5816 5.54599 11.1685 5.58484M8.46977 7.47269H4.50737M4.50737 7.47269C4.57248 6.27831 5.07832 4.95995 6.39677 3.64151C6.51838 3.51989 6.64034 3.40623 6.7625 3.30014M6.7625 3.30014L9.57875 6.11638M6.7625 3.30014C8.29086 1.97287 9.85122 1.83084 11.1685 2.13326M11.1685 5.58484C11.643 5.61624 12.0619 5.82951 12.387 6.13657C12.6393 6.37488 12.9743 6.70073 13.3042 7.0247M11.1685 5.58484L11.1685 2.13326M14.1665 3.67417C14.3992 3.86587 14.528 3.99459 14.5286 3.99515L15.5892 5.05572C16.1749 5.64151 16.1749 6.59125 15.5892 7.17704C15.0034 7.76283 14.0539 7.76263 13.4636 7.18138L13.3042 7.0247M14.1665 3.67417C13.6266 3.22939 12.5268 2.44507 11.1685 2.13326M14.1665 3.67417L13.3042 7.0247M14.1665 3.67417L14.1749 3.64151"
      />
    </svg>
  );
}

export default CandyCaneIcon;


