import React from 'react';
import {
  Button,
  Text,
  Modal,
  Group,
 } from '@mantine/core';

function ConfirmModal(props) {

  const {
    onSubmit,
    title,
    message,
    opened,
    onClose,
    submitText,
    submitColor = 'default',
  } = props;

  return (
  <Modal opened={opened} onClose={onClose} title={title}>
    {message && <Text size='sm'>{message}</Text>}
    <Group mt='md' style={{ justifyContent: 'flex-end' }}>
      <Button color={submitColor} onClick={onSubmit}>{submitText}</Button>
    </Group>
  </Modal>
  );
};

export default ConfirmModal;