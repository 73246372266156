import React from 'react';
import { Modal, Text, Group, Button, NumberInput, Stack, Alert, useMantineTheme } from '@mantine/core';
import { HiOutlineArrowDownTray, HiCheckCircle, HiOutlineInformationCircle } from 'react-icons/hi2';

function GenerateModal(props) {
  
  const theme = useMantineTheme();

  const {
    opened,
    close,
    gameOptions,
    onUpdate,
    isLoading,
    onSubmit,
    isGenerating,
    pdfInfo,
  } = props;

  const DownloadMenu = <Stack align='center' spacing='xl'>
      <Stack align='center' spacing='md'> 
        <HiCheckCircle color={theme.colors.teal[6]} size={64} />
        <Stack spacing={4}>
          <Text size='md' weight={600} align='center'>
            Cards generated successfully
          </Text>
          <Text size='sm' align='center'>
            Have a great game!
          </Text>
        </Stack>
      </Stack>
      <Stack spacing='sm' mb='xl'>
        <Button
          component='a'
          href={pdfInfo}
          leftIcon={<HiOutlineArrowDownTray size={20} />}
          target='_blank'
        >
          Download game
        </Button>
        <Button variant='light' onClick={close}>
          Dismiss
        </Button>
      </Stack>
  </Stack>

  const GenerateMenu = <>
    <Alert
      mb='lg'
      icon={<HiOutlineInformationCircle size={24} />}
      title="Colors may render differently"
    >
      Your generated PDF may look darker than expected. Don&apos;t worry – this is to ensure it prints correctly.
    </Alert>

    <NumberInput
      value={gameOptions?.printOptions?.pages}
      onChange={(val) => onUpdate('printOptions.pages', val)}
      placeholder='150'
      label={<Group style={{ justifyContent: 'space-between'}}>
        <Text size='sm'>Total pages</Text>
        <Text size='sm' c='dimmed' align='right'>2 cards per page</Text>
      </Group>}
      labelProps={{ style: { width: '100%' }}}
      step={10}
      min={10}
      max={300}
      disabled={isGenerating}
    />
    
    <Group w='100%' mt='xl' mb='sm'>
      <Text c='dimmed' size='sm' style={{ flex: 1 }}>
        Takes 30 seconds to complete.
      </Text>
      <Button
        onClick={onSubmit}
        disabled={isLoading}
        loading={isGenerating}
      >
          Generate cards
        </Button>
    </Group>
  </>

  const getContent = () => {
    if (!isGenerating && pdfInfo) {
      return DownloadMenu;
    }
    return GenerateMenu;
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={!pdfInfo && 'Generate cards'}
    >
      {getContent()}
    </Modal>
  );
}

export default GenerateModal;