import React from 'react';
import { TextInput, Button, Box } from '@mantine/core';
import { useForm } from '@mantine/form';

function TrackEditor(props) {

  const {
    trackIndex,
    track,
    isLoading,
    onSubmit,
  } = props;

  const form = useForm({
    initialValues: {
      name: track.name,
      artist: track.artists.length > 0 && track.artists[0].name,
    },
  });

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSubmit(trackIndex, values))}>
        <TextInput
          withAsterisk
          label='Song name'
          placeholder='Never Gonna Give You Up'
          {...form.getInputProps('name')}
        />

        <TextInput
          withAsterisk
          label='Artist'
          placeholder='Rick Astley'
          mt='md'
          {...form.getInputProps('artist')}
        />

        <Button fullWidth loading={isLoading} mt='lg' type='submit'>Update track</Button>
      </form>
    </Box>
  );
}

export default TrackEditor;


