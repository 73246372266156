import React, { forwardRef } from 'react';
import { Flex } from '@mantine/core';

const PlaylistFlexContainer = forwardRef(({ rounded, p = 'xs', offset, children, onClick, disabled }, ref) => (
    <Flex
      onClick={(e) => (!disabled && onClick) && onClick(e)}
      direction='row'
      justify='space-between'
      align='center'
      gap='md'
      wrap='nowrap'
      radius={rounded && 'sm'}
      p={p}
      // mx={offset && `-${p}`}
      w='100%'
      tabIndex={0}
      sx={(theme) => ({
        cursor: 'pointer',
        // transition: `120ms ${theme.transitionTimingFunction} all`,
        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        },
        '&:active, &:focus': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
          transform: `translateY(0.0625rem)`,
        }
      })}
      ref={ref}
    >
      {children}
    </Flex>
  ));

export default PlaylistFlexContainer;