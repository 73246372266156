import React from 'react';
import { Group, Text, useMantineTheme, Stack, createStyles } from '@mantine/core';
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from '@mantine/dropzone';

function FileUpload(props) {

  const {
    disabled,
    featuredImageUrl,
    onUpdate,
    isLoading,
  } = props;

  const featuredImageStyle = {
    maxWidth: '100%',
    opacity: disabled ? 0.5 : 1,
  };

  const useStyles = createStyles((theme) => ({
    disabled: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2],
      cursor: 'not-allowed',
  
      '& *': {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
      },
    },
  }));

  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Dropzone
      onDrop={onUpdate}
      onReject={(files) => console.log('rejected files', files)}
      maxSize={5 * 1024 ** 2} // 5 MB
      accept={IMAGE_MIME_TYPE}
      disabled={disabled}
      className={disabled && classes.disabled}
      mb='md'
      loading={isLoading}
    >
      <Group position='center' spacing='md' style={{ pointerEvents: 'none' }}>
        <Dropzone.Accept>
          Accepted
        </Dropzone.Accept>
        <Dropzone.Reject>
          Rejected!
        </Dropzone.Reject>

        <Dropzone.Idle>
          <img alt='featured' src={featuredImageUrl} style={featuredImageStyle} />
        </Dropzone.Idle>

        <div>
          <Text size='sm' align='center'>
            Drag & drop or select file
          </Text>
          <Stack spacing='none'>
            <Text size='sm' color='dimmed' align='center'>Recommended: 344×68px (5:1)</Text>
            <Text size='sm' color='dimmed' align='center'>JPEG, PNG, or SVG. Max 5MB.</Text>
          </Stack>
        </div>
      </Group>
    </Dropzone>
  );
}

export default FileUpload;