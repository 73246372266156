import React from 'react';
import { AppShell, Header, Flex, ActionIcon, Text } from '@mantine/core';
import { HiOutlineChevronLeft } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';

function GameShell({ children, showBackNavigation = true }) {

  return (
    <AppShell
      padding='md'
      header={
        <Header height={64}>
          <Flex
            justify='space-between'
            align='center'
            py='sm'
            px='xl'
          >      
            <Flex direction='row' align='center' gap='xs'>

              { showBackNavigation && 
                <Link to='/games' style={{ textDecoration: 'none' }}>
                  <ActionIcon size='md'>
                    <HiOutlineChevronLeft color='gray.9' />
                  </ActionIcon>
                </Link>
              }
              <Text
                transform='uppercase'
                size='xl'
                weight={500}
                color='gray.9'
                style={{ fontFamily: 'Staatliches, Oswald, sans-serif' }}
              >
                Music bingo
              </Text>
            </Flex>
            <UserMenu />
          </Flex>
        </Header>
      }
      styles={(theme) => ({
        main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
      })}
    >
      {children}
    </AppShell>
  );
};

export default GameShell;