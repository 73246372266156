import React from 'react';
import { useMantineTheme,  } from '@mantine/core';
import QRCode from "react-qr-code";
import QrCodeSvg from './qrcode.svg';

function QrCode(props) {
  const { size, value } = props;
  const theme = useMantineTheme();

  const qrContainerStyle = {
    boxSizing: 'border-box',
    padding: 8,
    border: `1px solid ${theme.colors.gray[3]}`,
    background: theme.colors.gray[1],
    borderRadius: 3,
    width: size,
    height: size,
  }

  const qrCodeStyle = {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
  };
  
  

  return (
    <div style={qrContainerStyle}>
      <QRCode
        size={size}
        style={qrCodeStyle}
        value={value}
        bgColor={theme.colors.gray[1]}
        fgColor={theme.colors.gray[9]}
        viewBox={`0 0 ${size} ${size}`}
      />
      {/* <img alt='qr code example' src={QrCodeSvg} style={qrCodeStyle} /> */}
    </div>
  );
}

export default QrCode;