import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { decodeToken, isExpired } from 'react-jwt';

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);
export const getToken = () => window.localStorage.getItem('token');
export const setToken = (token) => window.localStorage.setItem('token', token);
export const refreshToken = () => fetch(`${process.env.REACT_APP_API_HOST}/auth/refresh`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    }
  });

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);
  
  const login = (token) => {
    // After successful login, set isAuthenticated to true and store the JWT
    setIsAuthenticated(true);
    setToken(token);
  };

  const logout = () => {
    // Clear the JWT, set isAuthenticated to false, and clear user data
    window.localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
  };

  const keepTokenFresh = async () => {
    const token = getToken();
    if (token) {
      const decodedToken = decodeToken(token);
      const { exp } = decodedToken;
      const secondsRemaining = Math.floor(exp - (new Date().getTime()) / 1000);
      if (secondsRemaining <= 500) { // expires in 5 mins or less
        try {
          const data = await refreshToken();
          const newData = await data.json();
          setToken(newData.token);  
        } catch(error) {
          logout();
        }
      }
      setTimeout(keepTokenFresh, 30000); // check every 30 seconds
    }
  };


  // Function to check authentication when the app loads
  const checkAuthentication = () => {
    const token = getToken();

    if (token) {
      // Use react-jwt to decode and verify the token
      const decodedToken = decodeToken(token);
      const tokenIsExpired = isExpired(token);

      if (!tokenIsExpired) {
        setIsAuthenticated(true);
        setUser(decodedToken); // Set user data from the token
        keepTokenFresh(); // See if token expires soon & refresh it
      } else {
        logout();
      }
    } else {
      logout();
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const authContextValue = useMemo(() => ({
    isAuthenticated,
    user,
    login,
    logout,
  }), [isAuthenticated, user, login, logout]);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}
