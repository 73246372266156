import React, { useEffect, useState } from 'react';
import { 
  Accordion,
  Slider,
  Text,
  Group,
  Stack,
  ColorSwatch,
  TextInput,
  Tooltip,
  NumberInput,
  Checkbox,
  useMantineTheme,
} from '@mantine/core';
import { HiCheck } from 'react-icons/hi2';
import FontSelector from '../FontSelector';

function ThemePanel(props) {

  const {
    gameOptions,
    onUpdate,
  } = props;

  const {
    textOptions,
    title,
    qrCode
  } = gameOptions;

  const theme = useMantineTheme();
  const [checked, setChecked] = useState(null);

  const getSwatches = () => {
    const exceptions = ['spotify', 'dark'];
    const filtered = Object.keys(theme.colors)
      .filter((color) => !exceptions.includes(color))
      .reduce((obj, key) => ({
          ...obj,
          [key]: theme.colors[key],
        }), {});

    return Object.keys(filtered).map((color, idx) => (
      <Tooltip label={idx === 0 ? 'Grayscale' : `${color[0].toUpperCase()}${color.slice(1)}`} key={color}>
        <ColorSwatch
          component="button"
          onClick={() => {
            setChecked(color);
            onUpdate('themeColor', filtered[color][9])}
          }
          color={filtered[color][idx === 0 ? 9 : 6]}
          sx={{ color: '#fff', cursor: 'pointer' }}
        >
          {color === checked && <HiCheck size={16} />}
        </ColorSwatch>
      </Tooltip>
    ));
  }

  useEffect(() => {
    Object.keys(theme.colors).forEach((color, idx) => {
      if (theme.colors[color][9] === gameOptions.themeColor) {
          setChecked(color);
      }
    })
  }, []);

  return (
      <Accordion.Item value='header'>
        <Accordion.Control px='md'>Header</Accordion.Control>
        <Accordion.Panel pb='sm'>

            <TextInput
              mb='md'
              label='Game title'
              placeholder='Fun bingo game'
              labelProps={{ style: { fontWeight: 400, color: '#000' }}}
              value={title}
              onChange={(e) => onUpdate('title', e.currentTarget.value)}
            />

            <FontSelector
              onUpdate={(val) => onUpdate('textOptions.headerFont', val)}
              selectedFont={textOptions.headerFont}
            />

            <Checkbox
              my='md'
              checked={textOptions.headerBold}
              label='Bold text'
              style={{fontWeight: 600 }}
              onChange={(e) => onUpdate('textOptions.headerBold', e.currentTarget.checked)}
            />
            
            <Stack spacing={4} mt='md' mb='2.5rem'>
              <Group spacing={4}>
                <Text size='sm'>Size:</Text>
                <NumberInput
                  placeholder={28}
                  value={textOptions.headerSize}
                  variant='unstyled'
                  onChange={(val) => onUpdate('textOptions.headerSize', val)}
                />
              </Group>
              <Slider
                style={{ flex: 1 }}
                color='blue'
                min={28}
                max={40}
                label={null}
                step={4}
                marks={[
                  { value: 28, label: 'S' },
                  { value: 32, label: 'M' },
                  { value: 36, label: 'L' },
                  { value: 40, label: 'XL' },
                ]}
                value={textOptions.headerSize}
                onChange={(val) => onUpdate('textOptions.headerSize', val, true)}
                onChangeEnd={(val) => onUpdate('textOptions.headerSize', val)}
              />
            </Stack>

            <Stack mt='md' spacing='xs'>
              <Text size='sm'>Color</Text>
              <Group position='left' spacing='xs'>
                {getSwatches()}
              </Group>
            </Stack>

        </Accordion.Panel>
      </Accordion.Item>
  );
};

export default ThemePanel;