import { getToken } from './Auth';

const getPlaylists = (userId, page) => 
  fetch(`${process.env.REACT_APP_API_HOST}/playlists/${userId}?page=${page}`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    }
  }
);

const getGame = (playlistId) => 
  fetch(`${process.env.REACT_APP_API_HOST}/game/${playlistId}`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    }
  }
);

const getAllGames = () => 
  fetch(`${process.env.REACT_APP_API_HOST}/games`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    }
  }
);

const updateGame = (playlistId, payload) => 
  fetch(`${process.env.REACT_APP_API_HOST}/game/${playlistId}`, {
    method: 'PUT',
    headers: {
      authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
);

const uploadFile = (playlistId, file) => {
  const formData = new FormData();
  formData.append('file', file[0]);
  return (
    fetch(`${process.env.REACT_APP_API_HOST}/game/${playlistId}/upload`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
      body: formData,
    }
  ))
};


const resetPlaylist = (gameId) => 
  fetch(`${process.env.REACT_APP_API_HOST}/game/${gameId}/refresh`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    }
  }
);

const sanitizeGameTracks = (gameId) =>  
  fetch(`${process.env.REACT_APP_API_HOST}/game/${gameId}/sanitize`, {
    method: 'PUT',
    headers: {
      authorization: `Bearer ${getToken()}`,
    }
  }
);

const generateGame = (gameId) =>  
  fetch(`${process.env.REACT_APP_API_HOST}/game/${gameId}/generate`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${getToken()}`,
    }
  }
);

export {
  getPlaylists,
  getGame,
  resetPlaylist,
  updateGame,
  getAllGames,
  uploadFile,
  generateGame,
  sanitizeGameTracks,
};

export default getPlaylists;
