function Font(fontFamily){
    const fontObj = {
        label: fontFamily.split(',')[0],
        value: fontFamily,
        style: { fontFamily },
    }
    return fontObj;
}

const fonts = [
    new Font('Abril Fatface, cursive'),
    new Font('Acme, cursive'),
    new Font('Audiowide, cursive'),
    new Font('Bangers, sans-serif'),
    new Font('Berkshire Swash, cursive'),
    new Font('Boogaloo, sans-serif'),
    new Font('Bungee, sans-serif'),
    new Font('Carter One, sans-serif'),
    new Font('Concert One, sans-serif'),
    new Font('Covered By Your Grace, cursive'),
    new Font('Creepster, sans-serif'),
    new Font('Fredericka the Great, cursive'),
    new Font('Fugaz One, cursive'),
    new Font('Gloria Hallelujah, serif'),
    new Font('Graduate, serif'),
    new Font('Handlee, cursive'),
    new Font('Indie Flower, cursive'),
    new Font('Itim, sans-serif'),
    new Font('Josefin Sans, sans-serif'),
    new Font('Kaushan Script, cursive'),
    new Font('Knewave, cursive'),
    new Font('Lato, sans-serif'),
    new Font('Lemon, cursive'),
    new Font('Limelight, serif'),
    new Font('Lobster, cursive'),
    new Font('Luckiest Guy, sans-serif'),
    new Font('Monoton, sans-serif'),
    new Font('Montserrat Alternates, sans-serif'),
    new Font('Open Sans, sans-serif'),
    new Font('Oswald, sans-serif'),
    new Font('Pacifico, cursive'),
    new Font('Permanent Marker, cursive'),
    new Font('Poppins, sans-serif'),
    new Font('Raleway, sans-serif'),
    new Font('Rancho, cursive'),
    new Font('Roboto, sans-serif'),
    new Font('Rock Salt, cursive'),
    new Font('Rowdies, sans-serif'),
    new Font('Rye, serif'),
    new Font('Satisfy, cursive'),
    new Font('Shadows Into Light, cursive'),
    new Font('Sigmar One, sans-serif'),
    new Font('Silkscreen, sans-serif'),
    new Font('Special Elite, monospace'),
    new Font('Sriracha, cursive'),
    new Font('Staatliches, sans-serif'),
    new Font('Yatra One, sans-serif'),
];

const loadFonts = () => {
    const links = document.getElementsByTagName('link');
    const googleFontIndex = [...links].findIndex((link) => {
        const href = link.getAttribute('href');
        return href.includes('fonts.googleapis.com');
    });

    if (googleFontIndex === -1) {
        const fontsToLoad = fonts.map((font) => font.label).join('|').replace(/ /g, '+');
        const linkElem = document.createElement('link');
        linkElem.rel = 'stylesheet';
        document.head.appendChild(linkElem);
        linkElem.href = `https://fonts.googleapis.com/css?family=${fontsToLoad}&display=swap`;
    }
};

export {
    fonts,
    loadFonts,
};
export default fonts;
