import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginRoute from './containers/LoginRoute';
import PrivateRoute from './components/PrivateRoute';
import Playlists from './components/Playlists';
import GamesList from './containers/GamesList';
import Game from './containers/Game';
import { loadFonts } from './components/FontSelector/googleFonts';

function App() {

  useEffect(() => {
    loadFonts();
  }, [])

  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route index path='/login' element={<LoginRoute />}/>
          <Route index path='/login/success/:token' element={<LoginRoute />}/>
          <Route exact path='/games/new' element={<PrivateRoute/>}>
            <Route exact path='/games/new' element={<Playlists/>}/>
          </Route>
          <Route exact path='/games' element={<PrivateRoute/>}>
            <Route exact path='/games' element={<GamesList/>}/>
          </Route>
          <Route exact path='/games/:gameId' element={<PrivateRoute/>}>
            <Route exact path='/games/:gameId' element={<Game />}/>
          </Route>
          <Route path='*' element={<Navigate to='/games/new' replace />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
