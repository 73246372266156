import React from 'react';

function SnowflakeIcon(props) {

  const {
    size = 24,
    color = 'currentColor',
    style,
    strokeWidth = 1.5,
  } = props;

  return (
    <svg style={style} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        d="M12 10V6M12 10L11.134 10.5L10.701 10.75M12 10L12.866 10.5L13.299 10.75M13.732 11V12M13.732 11V13M13.732 11L13.299 10.75M13.732 13V12M13.732 13L13.299 13.25M12 14L12 18M12 14L12.866 13.5L13.299 13.25M12 14L11.134 13.5L10.701 13.25M12 2V6M12 6L14.5 3.5M12 6L9.5 3.5M13.732 12H17.866M22 12L17.866 12M17.866 12L20.5 9.5M17.866 12L20.5 14.5M12 22L12 18M12 18L14.5 20.5M12 18L9.5 20.5M10.2679 12V13L10.701 13.25M10.2679 12V11L10.701 10.75M10.2679 12H6.13397M2 12H6.13397M6.13397 12L3.5 9.5M6.13397 12L3.5 14.5M10.701 13.25L6.47548 17.4755M13.299 10.75L17.5245 6.52452M13.299 13.25L17.5245 17.4755M10.701 10.75L6.47548 6.52452"
      />
    </svg>
  );
}

export default SnowflakeIcon;


