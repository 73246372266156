import React from 'react';
import { Accordion, ScrollArea } from '@mantine/core';

import HeaderPanel from './HeaderPanel';
import CardsPanel from './CardsPanel';
import FooterPanel from './FooterPanel';

function GameEditor(props) {

  const {
    playlist,
    gameOptions,
    isLoading,
    onUpdate,
    isFileLoading,
    onFileUpload,
  } = props;

  return (
    <Accordion defaultValue='header'>
      <HeaderPanel gameOptions={gameOptions} onUpdate={onUpdate} />
      <CardsPanel gameOptions={gameOptions} onUpdate={onUpdate} />
      <FooterPanel
        playlist={playlist}
        gameOptions={gameOptions}
        onUpdate={onUpdate}
        onFileUpload={onFileUpload}
        isFileLoading={isFileLoading}
        isLoading={isLoading}
      />
    </Accordion>  
  );
};

export default GameEditor;