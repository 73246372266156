import React from 'react';
import { Avatar } from '@mantine/core';
import { HiOutlineMusicalNote } from 'react-icons/hi2';

function PlaylistArtwork(props) {

  const {
    playlist,
  } = props;

  return (
      <Avatar
        size={48}
        imageProps={{ loading: 'lazy' }}
        src={playlist?.images[0]?.url}
        alt={`Artwork for the playlist ${playlist?.gameOptions?.title}`}
      >
        <HiOutlineMusicalNote size={24} />
      </Avatar>
  );
}

export default PlaylistArtwork;