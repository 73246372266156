import React, { useEffect, useState, useRef } from 'react';
import { Flex, SegmentedControl, Container, Paper, Loader } from '@mantine/core';
import BingoCard from './BingoCard';
import GamePagePreview from './GamePagePreview';
import { shuffle, chunk, slice } from '../utils';

function GamePreview(props) {
  const GAME_WIDTH = 1056;
  const { gameOptions, tracks, isLoading } = props;
  const containerRef = useRef(null);

  const [gameScale, setGameScale] = useState(1);
  const [previewMode, setPreviewMode] = useState(null);
  const [previewTracks, setPreviewTracks] = useState([]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const getPreviewTracks = () => {
    if (tracks) {
      let newTracks = [...tracks];
          newTracks = shuffle(newTracks);
          newTracks = slice(newTracks);
          newTracks = chunk(newTracks);
      return newTracks;
    }
    return [];
  };

  const updatePreview = () => {
    const gamePreviewTracks = [
      getPreviewTracks(),
      getPreviewTracks(),
    ];
    setPreviewTracks(gamePreviewTracks);
  };

  const scaleCard = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const scale = parseFloat((containerWidth / GAME_WIDTH).toFixed(2));	
      setGameScale(scale <= 1 ? scale : 1);
    }
  }

  useEffect(() => {
    updatePreview();
    setPreviewMode('game');
  }, [tracks]);

  useEffect(() => {
    scaleCard();
    window.addEventListener('resize', scaleCard);
    return () => window.removeEventListener('resize', scaleCard);
  }, [previewMode]);

  return (
    <Flex
      direction='column'
      align='center'
    >
      <SegmentedControl
        value={previewMode}
        onChange={setPreviewMode}
        data={[
          { label: 'Game', value: 'game' },
          { label: 'Card', value: 'card' },
        ]}
        mb='md'
      />

      { previewMode === 'card' ?
        <Paper
          shadow='md'
          p='md'
          pt='xl'
          pb='lg'
          maw={600}
          w={600}
          miw={600}
          mih={600}
          onMouseEnter={() => setIsOverlayVisible(true)}
          onMouseLeave={() => setIsOverlayVisible(false)}
          pos='relative'
          display='flex'
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          { !isLoading ? 
            <BingoCard tracks={previewTracks[0]} gameOptions={gameOptions} updatePreview={updatePreview} isOverlayVisible={isOverlayVisible} />
            :
            <Loader color='blue' />
          }
        </Paper>
        :
        <Container
          ref={containerRef}
          w='100%'
          h='100%'
          maw={GAME_WIDTH}
          p={0}
        > 
          <GamePagePreview
            tracks={previewTracks} 
            gameOptions={gameOptions}
            scale={gameScale}
            isLoading={isLoading}
          />
        </Container>
      }
    </Flex>
  );
}

export default GamePreview;